@import 'root.scss';

@include switch-theme {
  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none !important;
    width: 100%;
    padding: 1rem 0 3rem 0;
    margin: 0 auto;

    @include widths;

    @include phone {
      flex-direction: column;
      align-items: center;

      .logos {
        margin-bottom: 1rem !important;
        scale: 0.9;
      }
    }

    .logos {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      margin: 0;
      padding: 0 1rem;

      p {
        line-height: 100%;
        font-size: 1.6rem;
        // font-family: 'Poppins', sans-serif;
        white-space: nowrap;
        color: $dark-grey;
      }

      .separator {
        height: 3rem;
        width: 0.07rem;
        background: $grey;
      }

      img {
        filter: saturate(0);
      }

      .design-lab {
        width: 7rem;
      }
    }

    .rights {
      color: $grey;
      white-space: nowrap;
      font-weight: 300;
      line-height: 150%;
      font-size: 1rem;
      padding: 0 1rem;
      margin: 0;
      text-align: center;

      .large-screen-dot {
        @include phone {
          display: none;
        }
      }
    }

    .small-screen-break {
      @include tablet {
        display: none;
      }
    }
  }
}
