@import 'root.scss';

@include switch-theme {
  .map-container {
    @include widths-header;
    margin: 0 auto;
    height: 35rem;
    width: 100%;
  }

  .contact-info {
    @include widths;

    font-size: 1rem;
    display: flex;
    justify-content: space-evenly;
    gap: 5rem;

    @include phone {
      flex-direction: column;
      gap: 2rem;
      margin: 3rem 5%;
    }

    @include tablet {
      margin: 6rem auto 6rem auto;
    }

    * {
      // flex-basis: 100%;
      line-height: 170%;
    }

    p {
      flex-shrink: 1;
    }

    ul {
      flex-grow: 2;
      list-style: none;
      white-space: nowrap;
      font-weight: 500;

      li {
        margin-bottom: 1rem;

        p {
          position: relative;
          cursor: pointer;
          // border-bottom: 1px dotted $grey;
        }

        .copy-message {
          position: absolute;
          display: block;
          left: 0;
          top: 100%;
          // background: $contact;
          padding: 0.5rem;
          white-space: nowrap;
          color: $white;
          line-height: 100%;
          font-weight: 400;
          font-size: smaller;
          font-style: inherit;
          display: none;
        }

        &:hover {
          .copy-message {
            display: block;
          }
        }
      }
    }
  }

  // .gmnoprint {
  //   display: none;
  //   opacity: 0.5;
  // }
}
