// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import 'root.scss';

@include switch-theme {
  header {
    position: fixed;
    display: flex;
    margin: 0 auto;
    // padding: 1rem 0;
    right: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: fit-content;
    background-color: $white;
    // height: 3rem;
    justify-content: space-between;
    align-content: center;
    align-items: stretch;
    backdrop-filter: blur(9px);
    z-index: 99;
    user-select: none;
    // border-bottom: 1px solid $light-light-grey;

    @include widths-header;

    #header-menu {
      flex-grow: 2;
      display: flex;
      align-self: stretch;
      align-items: stretch;
      justify-content: flex-end;
      white-space: nowrap;
      transition-duration: 0.2s;

      position: absolute;
      right: 0;
      height: 100%;

      @include phone {
        top: 0;
        position: relative !important;
        justify-content: center;
        height: unset !important;

        a {
          padding: 1rem 0.5rem !important;
        }
      }

      @include tablet {
        gap: 0.5rem;

        a {
          padding: 0 0.5rem !important;
        }
      }

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        height: 100%;
        list-style: none;
        text-decoration: none;
        box-sizing: border-box;

        a {
          display: flex;
          align-items: center;

          text-decoration: none;
          font-weight: 300;

          font-size: 1rem;
          height: 100%;
          border: none;
          padding: 0;
          box-sizing: border-box;
          vertical-align: middle;
          text-align: center;

          box-shadow: inset transparent 0 0 0 0;
          // transition: box-shadow 0.2s;

          * {
            font-family: 'Mukta', sans-serif;
            color: $grey;
          }

          &.highlighted {
            * {
              color: $dark;
            }

            // &.research,
            // &.team,
            // &.contact,
            // &.join {
            //   box-shadow: inset $light-light-grey 0 -3px 0 0;
            // }
          }

          // &:hover,
          // &:focus,
          // &:active {
          //   * {
          //     color: $white;
          //   }

          //   &.header-link {
          //     box-shadow: inset $yellow 0 -3px 0 0;
          //   }
          // }
        }
      }
    }
  }

  @include desktop {
    header {
      #header-menu {
        gap: 3rem;
      }
    }
  }
}

.lab-title {
  font-family: 'Mukta', sans-serif;
  margin: 0 !important;
  padding: 1.25rem !important;
  font-size: 1.5rem !important;
  line-height: 100%;
  font-weight: 300 !important;
  white-space: nowrap !important;

  border: none !important;

  &#lab-title-header {
    @include phone {
      display: none !important;
    }
  }
}
