@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;300;400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap');
@import 'root.scss';

@include switch-theme {
  * {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: none;

    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    color: $black;
  }

  *::selection {
    background: $light-grey;
  }

  body,
  html {
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
    color: inherit;
    font-weight: inherit;
    // border-bottom: 1px dotted $grey;
    // border-bottom: 2px solid $yellow;

    &:visited {
      color: inherit;
    }

    &:hover {
      color: inherit;
    }

    &:active {
      color: inherit;
    }
  }
}
