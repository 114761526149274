@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');
@import 'root.scss';

@include switch-theme {
  .page-view {
    margin: 0 auto 6rem auto;
    // width: 100%;
    overflow-wrap: normal;
    overflow: hidden;
    transition-duration: 0.2s;

    @include widths;

    * {
      line-height: 170%;
    }

    h1 {
      font-weight: 300;
    }

    h2 {
      margin: 2rem 0 0.5rem 0;
      font-weight: 300;
    }

    p {
      margin: 0 0 1.5rem 0;
    }

    li {
      list-style: circle;
    }
  }

  .project-page-view {
    margin: 10rem auto 6rem auto !important;

    @include phone {
      margin: 6rem auto 6rem auto !important;
    }

    .project-meta {
      margin: 0 0 3rem 0;

      .project-authors {
        font-weight: 300;
        font-size: 1.3rem;
      }

      .list-item-links {
        justify-content: flex-start;

        li {
          list-style: none;
        }
      }

      // for people bio
      .business-card {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 1.5rem;

        margin: 0;
        padding: 0 0 2rem 0;
        transition: padding 0.2s;

        @include phone {
          padding: 2rem 0 0 0 !important;
        }

        img {
          width: 7.5rem; // 120px
          height: 9.375rem; // 150px

          object-fit: cover;
          filter: saturate(80%);
        }

        .card-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          margin: 0;
          padding: 0;

          h1 {
            line-height: 100% !important;
            margin: 0;
            padding: 0;
          }

          .project-authors {
            line-height: 130% !important;
            margin: 0;
          }
        }
      }
    }

    .iframe-container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      margin: 0 0 2rem 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    blockquote {
      padding: 0 1rem;
      border-left: 2px solid $light-grey;
      // font-style: italic;
    }

    pre {
      background: $light-light-grey;
      font-size: 0.9rem;
      padding: 0.7rem 1rem;
      line-height: 130%;

      * {
        line-height: 130%;
        font-family: 'Inconsolata', 'Courier New', Courier, monospace;
        white-space: pre-wrap;
      }
    }
  }
}
