@import 'root.scss';

@include switch-theme {
  .project-filters {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    margin-bottom: 2rem;

    h3 {
      font-size: 1rem;
      font-weight: 300;
      color: $dark-grey;
      padding: 0 0.2rem;
    }

    .division {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;

      .filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 0.3rem 0.8rem;
      }
    }

    .filter-item {
      font-size: 1rem;
      color: $grey;
      font-weight: 500;
      white-space: nowrap;
      padding: 0.2rem;
      // border-bottom: 1px dotted transparent;
      border-radius: 5px;
      font-family: 'Mukta', sans-serif;

      position: relative;

      transition: all 0.2s;
      cursor: pointer;

      &.other-filter {
        font-weight: 400;
        font-style: italic;
      }

      &:hover:not(.selected-filter) {
        color: $dark !important;
        // border-bottom: 1px dotted $grey;
      }

      &:hover.selected-filter {
        background: $dark-grey;

        .selected-filter-cross {
          background: $dark-grey;
        }
      }

      &.selected-filter {
        color: $white;
        background: $grey;

        .selected-filter-cross {
          transform: translate(50%, -50%);
          opacity: 1;
        }
      }

      .selected-filter-cross {
        position: absolute;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 14px;
        height: 14px;
        background-color: $grey;

        top: 0;
        right: 0;
        transform: translate(0, 0);
        opacity: 0;

        transition: all 0.15s;

        svg {
          display: inline;
          width: 8px !important;
          height: 8px !important;

          path {
            fill: $light-light-grey;
          }
        }
      }
    }
  }
}
