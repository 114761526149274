@import 'root.scss';

@include switch-theme {
  .research-intro,
  .collection-intro {
    font-size: 1rem;
    line-height: 165%;
    transition-duration: 0.2s;
    // text-align: justify;
  }

  .research-intro {
    display: flex;
    gap: 2rem;

    p {
      padding: 0;
    }

    p:not(:first-of-type) {
      margin-top: 1rem;
    }

    ol.intro-list {
      counter-reset: li;
      list-style: none;
      padding-bottom: 1rem;

      li:before {
        content: counter(li) ') ';
        counter-increment: li;
      }
    }

    @include phone {
      flex-direction: column;

      font-size: 1rem;
      // width: 94%;
      margin: 8rem auto 4rem auto;
      // padding: 0 0.1rem;
    }

    @include tablet {
      flex-direction: row;

      margin: 10rem auto 7rem auto;
      // width: 85%;
    }

    @include desktop {
      margin: 11rem auto 7rem auto;
      // width: 60%;
    }

    @include desktop-big {
      margin: 12rem auto 10rem auto;
      // width: 30%;
    }
  }

  .collection-intro {
    @include phone {
      font-size: 1rem;
      margin: 0rem auto 2rem auto;
    }

    @include tablet {
      margin: 0rem auto 2rem auto;
    }

    @include desktop {
      margin: 0rem auto 2rem auto;
    }

    @include desktop-big {
      margin: 0rem auto 2rem;
    }
  }
}
