@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;500&display=swap');
@import 'root.scss';

$list-item-padding: 1.2rem;

@include switch-theme {
  .list-container {
    position: relative;
    margin: 0 auto;
    transition-duration: 0.2s;

    @include widths;
  }

  .list-view {
    margin: 0 auto;
    margin-bottom: 5rem;
    list-style: none;

    &.project-list {
      $theme-color: $research;
    }

    .list-item {
      // border-top: 1px solid $light-light-grey;
      margin: 0;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      width: 100%;

      background: $white;
      overflow: hidden;
      opacity: 1;
      transition: height 0.25s, opacity 0.35s; // !

      @include phone {
        &.project-item {
          flex-direction: column;
          justify-items: center;
          align-items: center;

          .project-content {
            align-self: stretch;
          }
        }
      }

      // .project-image {
      //   filter: grayscale(0);
      // }

      img,
      .people-image-placeholder {
        flex-shrink: 0;
        outline: none;
        // box-shadow: 0 0 0 1px $light-grey;
        border-radius: 9px;
        outline-offset: -1px;
        margin: $list-item-padding 0;
        padding: 0;
        object-fit: cover;
        filter: saturate(80%);
        user-select: none;

        @include phone {
          margin-left: 0.3rem;

          &.project-image {
            margin: 2rem 0 1rem;
          }
        }

        &.project-image-animated {
          display: none;
        }
      }

      &.project-item-has-animated:hover {
        img {
          display: none;
        }

        .project-image-animated {
          display: unset;
        }
      }

      .list-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: $list-item-padding $list-item-padding 0 1.2rem;

        transition: opacity 0.2s linear 0.2s; // !

        @include phone {
          &.project-content {
            padding: 0;
          }
        }

        &.people-content-alumni {
          padding: $list-item-padding $list-item-padding $list-item-padding
            1.2rem;

          .list-item-title {
            margin: 0 !important;
          }

          .person-item-title {
            display: flex;
            justify-content: space-between;
          }
        }

        .project-info {
          justify-content: space-between;

          .project-year {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 0.3rem;

            color: $dark-grey;
            font-weight: 300;

            .new-project-label {
              color: $purple;
              font-size: 0.7rem;
              font-weight: 400;

              padding: 0.05rem 0.25rem;
              border-radius: 0.6rem;
              border: 1px solid $purple;
            }
          }

          .project-award {
            color: $dark-grey;
            font-weight: 500;
            color: $gold;
          }
        }

        .people-info {
          justify-content: flex-end;

          .people-title {
            color: $grey;
          }
        }

        .project-info,
        .people-info {
          display: flex;

          *:not(.new-project-label) {
            // font-family: 'Roboto Slab', serif;
            font-family: 'Mukta', sans-serif;
            line-height: 100%;
            font-size: 0.9rem;
            font-weight: 500 !important;
          }
        }

        .list-content-center {
          flex-grow: 1;

          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 0.3rem;

          transition: opacity 0.1s;
        }

        .list-item-title {
          line-height: 140%;

          @include phone {
            font-size: 1rem;
          }
          @include tablet {
            font-size: 1.1rem;
          }
          @include desktop {
            font-size: 1.15rem;
          }

          * {
            color: $dark;
          }

          .item-name {
            font-weight: 700;
          }
          .item-title {
            font-weight: 400;
          }
        }

        .project-author,
        .people-research {
          color: $grey-almost;
          font-family: 'Mukta', sans-serif;
          font-weight: 500;
          font-size: 1rem;
          line-height: 140%;
        }

        // .people-bio {
        //   margin: 0 0 1rem 0;
        //   width: 95%;

        //   @include phone {
        //     width: 100%;
        //   }
        //   @include tablet {
        //     width: 100%;
        //   }
        //   @include desktop {
        //     width: 100%;
        //   }

        //   p {
        //     color: $grey-almost;
        //     font-weight: 400;
        //     font-size: 0.95rem;

        //     line-height: 160%;
        //     padding: 0;
        //     margin: 0;

        //     &:not(:last-child) {
        //       margin-bottom: 0.7rem;
        //     }
        //   }
        // }

        // .project-author:not(:last-of-type)::after {
        //   content: ' , ';
        // }

        &.list-content-hidden {
          opacity: 0 !important;

          .list-content-center {
            opacity: 0 !important;
          }
        }
      }

      .project-content {
        gap: 0.5rem;

        @include phone {
          gap: 0.7rem;
        }
      }

      .people-content {
        gap: 0.3rem;
      }

      &.list-item-hidden {
        border: 0px solid transparent !important;
        height: 0 !important;
        opacity: 0 !important;
      }
    }

    &.project-list {
      img {
        @include phone {
          width: 95vw;
          height: calc(95vw * 0.6);
        }
        @include tablet {
          width: 200px;
          height: 120px;
        }
        @include desktop {
          width: 250px;
          height: 150px;
        }
      }
    }

    &.people-list {
      &.people-list-alumni {
        @include tablet {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 0.5rem;
        }
        @include desktop {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 1rem;
        }
      }

      &.people-list-alumni:not(:last-of-type) {
        margin-bottom: 3rem;
      }

      .people-image-current {
        width: 7.5rem;
        height: 9.375rem;
      }

      .people-image-alumni {
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
      }

      .people-image-placeholder {
        background-color: $light-light-grey;
      }
    }
  }

  .list-h2 {
    display: inline-block;
    user-select: none;
    margin-bottom: 2rem;
    font-weight: 300;
    font-family: 'Mukta', sans-serif;
    width: 100%;
    text-align: center;

    &:first-child {
      margin-top: 11rem;
    }
  }

  .list-h3 {
    user-select: none;
    margin-bottom: 1rem;
    font-weight: 400;
    color: $dark-grey;
  }

  // .list-h2,
  // .list-h3 {
  //   @include phone {
  //     margin-left: 5%;
  //   }
  //   @include tablet {
  //     margin-left: 5%;
  //   }
  //   @include desktop {
  //     margin-left: 10%;
  //   }
  //   @include desktop-big {
  //     margin-left: 20%;
  //   }
  // }
}
