@mixin phone {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1000px) {
    @content;
  }
}

@mixin desktop-big {
  @media (min-width: 1800px) {
    @content;
  }
}

$opacity: 0.8;

$dark: #000 !default;
$black: #333 !default;
$dark-grey: #555 !default;
$grey: #777 !default;
$grey-almost: #8f8f8f !default;
$light-grey: #eaeaea !default;
$light-light-grey: #efefef !default;

$white: #fff !default;

$gold: #e5890a !default;
$purple: #d22eac !default;
$yellow: #ffc93c !default;

// $research: #ff5f00 !default;
// $team: #1db9c3 !default;
// $contact: #00af91 !default;
// $join: #8843f2 !default;

$research: $dark-grey !default;
$team: $dark-grey !default;
$contact: $dark-grey !default;
$join: $dark-grey !default;

@mixin light {
  $dark: #000;
  $black: #333;
  $dark-grey: #666;
  $grey: #777;
  $grey-almost: #8f8f8f;
  $light-grey: #eaeaea;
  $light-light-grey: #efefef;

  $white: #fff;

  $gold: #e5890a;
  $purple: #d22eac;
  $yellow: #ffc93c;

  $research: $dark-grey;
  $team: $dark-grey;
  $contact: $dark-grey;
  $join: $dark-grey;

  html,
  body {
    background-color: $white;
  }

  @content;
}

@mixin dark {
  $dark: #d0d0d0 !global;
  $black: #ccc !global;
  $dark-grey: #aaa !global;
  $grey: #999 !global;
  $grey-almost: #aaa !global;
  $light-grey: #777 !global;
  $light-light-grey: #444 !global;

  $white: #222 !global;

  $gold: #e5890aee !global;
  $purple: #d800a6ee !global;
  $yellow: #deb140 !global;

  // $research: #ff5f00 !global;
  // $team: #1db9c3 !global;
  // $contact: #00af91 !global;
  // $join: #8843f2 !global;
  $research: $black !global;
  $team: $black !global;
  $contact: $black !global;
  $join: $black !global;

  html,
  body {
    background-color: $white;
  }

  @content;
}

@mixin switch-theme {
  @media (prefers-color-scheme: light) {
    @include light {
      @content;
    }
  }

  @media (prefers-color-scheme: dark) {
    @include dark {
      @content;
    }
  }
}

@media (prefers-color-scheme: light) {
  .map-container {
    opacity: 1;
  }

  img {
    opacity: 1;
  }
}

@media (prefers-color-scheme: dark) {
  .map-container {
    opacity: 0.7;
  }

  img {
    opacity: 0.7;
  }
}

/* -------------------------------------------------------------------------- */

@mixin widths {
  @include phone {
    width: 95%;
  }

  @include tablet {
    width: 85%;
  }

  @include desktop {
    width: 80%;
    max-width: 950px;
  }

  @include desktop-big {
    width: 80%;
    max-width: 1050px;
  }
}

@mixin widths-header {
  @include widths;

  @include phone {
    width: 100%;
  }
}
